import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import collapse from '@alpinejs/collapse'
import Swiper, { Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import anime from 'animejs/lib/anime.es.js';
import 'animate.css';
import IntersectAnimate from 'alpine-intersect-animate';

Swiper.use([Pagination, Autoplay]);

const body = document.body;
const select = e => document.querySelector(e);
const selectAll = e => document.querySelectorAll(e);



window.addEventListener('load', () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// We listen to the resize event
window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});

Alpine.data('textSlider', () => ({
    initSlider(element) {
        const swiper = new Swiper(element, {
            modules: [Autoplay],
            direction: 'vertical',
            autoHeight: true,
            loop: true,
            slidesPerView: 1,
            autoplay: {
                delay: 4000,
            },
        });
    }
}));

function gradientHover(){
    var container = document.querySelector('.bg-gradient');

    container.onmousemove = function (e) {
        var x = e.pageX - container.offsetLeft
        var y = e.pageY - container.offsetTop
        container.style.setProperty('--x', x + 'px')
        container.style.setProperty('--y', y + 'px')
    }
}

window.Alpine = Alpine;
window.Swiper = Swiper;
window.Anime = anime;
window.gradientHover = gradientHover;

Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(IntersectAnimate);

Alpine.start();
